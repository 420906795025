<template>
  <r-e-dialog title="调整租金折扣" :visible="dialogVisible" show-footer @click-submit="handleSubmitPublish"
              @click-cancel="handleCancel" @close="handleCancel" width="600px">
    <el-form ref="formPublish" label-width="130px" size="small" :model="formPublish" >
      <!--          <span style="color: #4197f8">保障性租赁住房：</span>-->

      <el-form-item>
               <span slot="label">
                <span style="color: #4197f8; width: 130px">保障性租赁住房：</span>
               </span>
      </el-form-item>
      <el-form-item label="整租房:" >
        <el-input v-model="formPublish.wholeRentMin" style="width: 120px;" placeholder="折扣最小值"></el-input>
        <span style="margin-left: 22px">%</span>
        <span style="margin-left: 15px;margin-right: 15px">至</span>
        <el-input v-model="formPublish.wholeRentMax" style="width: 120px;" placeholder="折扣最大值"></el-input>
        <span style="margin-left: 22px">%</span>
      </el-form-item>
      <el-form-item label="分租房:">
        <el-input v-model="formPublish.subleaseMin" style="width: 120px;" placeholder="折扣最小值"></el-input>
        <span style="margin-left: 22px">%</span>
        <span style="margin-left: 15px;margin-right: 15px">至</span>
        <el-input v-model="formPublish.subleaseMax" style="width: 120px;" placeholder="折扣最大值"></el-input>
        <span style="margin-left: 22px">%</span>
      </el-form-item>
      <el-form-item>
               <span slot="label">
                <span style="color: #4197f8; width: 130px">长租公寓：</span>
               </span>
      </el-form-item>
      <el-form-item label="富航青年公寓:">
        <el-input v-model="formPublish.FHMin" :disabled="true" style="width: 120px;" placeholder="100"></el-input>
        <span style="margin-left: 22px">%</span>
        <span style="margin-left: 15px;margin-right: 15px">至</span>
        <el-input v-model="formPublish.FHMax" :disabled="true" style="width: 120px;" placeholder="100"></el-input>
        <span style="margin-left: 22px">%</span>
      </el-form-item>

    </el-form>
  </r-e-dialog>
</template>

<script>
import {ParseFloatFormat, ParseIntFormat} from "@custom/index";
import {communityAllrecoredtlook,communityAllrecoredtlookbtn} from "@/api/community-management";
import {MessageSuccess, MessageWarning} from "@custom/message";
import el from "element-ui/src/locale/lang/el";

export default {
  name: "layer-add-edit-unit-price",

  data() {


    return {
      dialogVisible: false,
      formPublishinfo:true,
      formPublish: {
        wholeRentMin: '',
        wholeRentMax: '',
        subleaseMin: '',
        subleaseMax: '',
        FHMin: '100',
        FHMax: '100',
      },


      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      },
    };
  },
  created() {
    // this.checkInput()
  },
  methods: {
    //打开编辑单价弹窗方法
    openDialog() {
      this.dialogVisible = true;
      this.checkInput()
    },
    checkInput(value, message) {
      let that = this;
      communityAllrecoredtlookbtn().then(res => {
        console.log(res.info)
          that.formPublish.wholeRentMin = res.info.wholeRentMin;
          that.formPublish.wholeRentMax = res.info.wholeRentMax;
          that.formPublish.subleaseMin = res.info.subleaseMin;
          that.formPublish.subleaseMax = res.info.subleaseMax;
          that.formPublish.FHMin = res.info.FHMin
          that.formPublish.FHMax = res.info.FHMax
      })
    },
     checkNegativeValue(value, variableName) {
  if (typeof value !== 'number') {
    console.error(`${variableName} is not a number.`);
    return false;
  }
  if (value < 0) {
    MessageWarning(`折扣值不可为负数`);
    return false;
  }
  return true;
},

    // 弹框确认方法
    handleSubmitPublish() {
      let that = this;
      let {wholeRentMin, wholeRentMax, subleaseMin, subleaseMax, FHMin, FHMax} = that.formPublish;
      wholeRentMin = Number(wholeRentMin);
      wholeRentMax = Number(wholeRentMax);
      subleaseMin = Number(subleaseMin);
      subleaseMax = Number(subleaseMax);
      FHMin = Number(FHMin);
      FHMax = Number(FHMax);
      if(wholeRentMin === '' || wholeRentMax === '' || subleaseMin === '' || subleaseMax === '' || FHMin === '' || FHMax === ''){
        MessageWarning('折扣值不能为空');
        return false
      }
      if (!this.checkNegativeValue(wholeRentMax, 'wholeRentMax')) return false;
      if (!this.checkNegativeValue(wholeRentMin, 'wholeRentMin')) return false;
      if (!this.checkNegativeValue(subleaseMin, 'subleaseMin')) return false;
      if (!this.checkNegativeValue(subleaseMax, 'subleaseMax')) return false;
      if (!this.checkNegativeValue(FHMin, 'FHMin')) return false;
      if (!this.checkNegativeValue(FHMax, 'FHMax')) return false;
      if(wholeRentMin > wholeRentMax){
        MessageWarning('折扣最大值不能小于折扣最小值');
        return false
      }
      if(subleaseMin > subleaseMax){
        MessageWarning('折扣最大值不能小于折扣最小值');
        return false
      }
      if(FHMin > FHMax){
        MessageWarning('折扣最大值不能小于折扣最小值');
        return false
      }
      // if(wholeRentMin === wholeRentMax && subleaseMin === subleaseMax && FHMin === FHMax){
      //   MessageWarning('折扣最大值不能小于折扣最小值');
      //   return false
      // }
      // if(wholeRentMin === wholeRentMax && subleaseMin === subleaseMax && FHMin === FHMax){
      //   MessageWarning('折扣最大值不能小于折扣最小值');
      //   return false
      // }
      // console.log({
      //   wholeRentMin: wholeRentMin,
      //   wholeRentMax: wholeRentMax,
      //   subleaseMin: subleaseMin,
      //   subleaseMax: subleaseMax,
      //   FHMin: FHMin,
      //   FHMax: FHMax,
      // })
      communityAllrecoredtlook({
        wholeRentMin: wholeRentMin,
        wholeRentMax: wholeRentMax,
        subleaseMin: subleaseMin,
        subleaseMax: subleaseMax,
        FHMin: FHMin,
        FHMax: FHMax,
      }).then(res => {
        console.log(res)
        MessageSuccess("编辑成功");
        that.handleCancel();
      }).finally()

    },
    // 弹框关闭方法
    handleCancel() {
      //重置表单
      this.$refs["formPublish"].resetFields();
      // 取消、关闭弹窗
      this.dialogVisible = false;
      //调用上层方法查询
      this.$emit("handleSearch");
    },

  }
}
</script>

<style scoped>

</style>